@font-face {
  font-family: 'Pragmatica';
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: url('./../shared/assets/fonts/Pragmatica/Pragmatica-ExtraBold.woff2') format('woff2')
}
@font-face {
  font-family: 'Pragmatica';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url('./../shared/assets/fonts/Pragmatica/Pragmatica-Bold.woff2') format('woff2')
}
@font-face {
  font-family: Pragmatica;
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url('./../shared/assets/fonts/Pragmatica/Pragmatica-Medium.woff2') format('woff2')
}
@font-face {
  font-family: Pragmatica;
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url('./../shared/assets/fonts/Pragmatica/Pragmatica-Book.woff2') format('woff2')
}
@font-face {
  font-family: Pragmatica;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url('./../shared/assets/fonts/Pragmatica/Pragmatica-Light.woff2') format('woff2')
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
